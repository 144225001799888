import React from 'react';
interface headerProps{
  classess?: string;
  heading?: string;
  subheading?: string;
  text?: string;
  children?: any;
}
const Heading: React.FC<headerProps> = ({classess, heading, subheading, text, children}) => {
  return(
    <div className={`heading ${classess ? classess : ''}`}>
      {heading && <div className='title'>{heading}</div>}
      {subheading && <div className='subTitle'>{subheading}</div>}
      {text && <p>{text}</p>}
      {children}
    </div>
  )
}

export default Heading;