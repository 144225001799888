import React from 'react';
//import { useTranslation } from 'react-i18next';
import Banner from './Components/Banner/Banner';
import Header from './Components/Header/Header';
import OurFeatures from './Components/OurFeatures/OurFeatures';
import GetInTouch from './Components/GetInTouch/GetInTouch';
import Copyright from './Components/Copyright/Copyright';

// interface LanguageOption {
//   value: string;
//   label: string;
// }

// const languageOptions: LanguageOption[] = [
//   { value: 'en', label: 'English' },
//   { value: 'fr', label: 'Français' },
//   // Add more languages as needed
// ];

const App: React.FC = () => {
  // const { t, i18n } = useTranslation();

  // const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedLanguage = event.target.value;
  //   i18n.changeLanguage(selectedLanguage);
  // };

  return (
    <div className="App">
      {/* <h1>{t('greeting')}</h1>
      <label htmlFor="language-select">Select Language: </label>
      <select
        id="language-select"
        onChange={changeLanguage}
        value={i18n.language}
      >
        {languageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}
      <Header />
      <Banner />
      <OurFeatures />
      <GetInTouch />
      <Copyright />
    </div>
  );
};

export default App;
