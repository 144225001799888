import React from 'react';
import { FeaturesStyle, WrapperStyle } from './OurFeatures.styles';
import { Container } from '../Container/Container';
import { Button, Col, Row, Typography } from 'antd';
import Advertising from '../Icons/Advertising';
import Capecity from '../Icons/Capecity';
import Touch from '../Icons/Touch';
import Card from '../Icons/Card';
import download from '../../Assets/Images/download.png';
import features from '../../Assets/Images/featuresHero.png';
import Accessible from '../Icons/Accessible';
import { useTranslation } from "react-i18next";
const { Title, Paragraph } = Typography;

const OurFeatures = () => {
  const { t } = useTranslation();
  const pdfUrl = '../../../Assets/Downloads/brochure.pdf';
  console.log('PDF URL:', pdfUrl);
const onButtonClick = () => {
  const link = document.createElement('a');
  link.href = pdfUrl;
  link.download = 'document.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  return(
    <WrapperStyle>
      <Container>
        <FeaturesStyle>
          <Typography>
            <Title level={2}>{t('ourFeaturesTitle')}</Title>
          </Typography>
          <div className='vendingMachin'>
            <div className='b'>
            <Row gutter={[16,16]}>
                <Col xs={12} md={12} lg={8} xl={8}>
                  <div className='featuresList'>
                    <Capecity />
                    <h4>{t('featuresCardOne')}</h4>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={8} xl={8}>
                  <div className='featuresList'>
                    <Touch />
                    <h4>{t('featuresCardTwo')}</h4>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={8} xl={8}>
                  <div className='featuresList'>
                    <Card />
                    <h4>{t('featuresCardThree')}</h4>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={8} xl={8}>
                  <div className='featuresList'>
                    <Advertising />
                    <h4>{t('featuresCardFour')}</h4>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={8} xl={8}>
                  <div className='featuresList'>
                    <Accessible />
                    <h4>{t('featuresCardFive')}</h4>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={8} xl={8}>
                  <div className='featuresList'>
                    <h4>{t('featuresCardSix')}</h4>
                    <div className='downloadCard'>
                      <img src={download} alt='Brochure'/>
                      {/* <Button className='downloadBtn' onClick={onButtonClick}>{t('downloadNow')}</Button>
                      <a href="../../JuicyVapes.pdf" download="Juicy-Vapes brochure" target='_blank'>*/}
                      <Button className='downloadBtn' href="../../JuicyVapes.pdf" download="Juicy-Vapes brochure" target='_blank'>{t('downloadNow')}</Button>
{/* </a> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </FeaturesStyle>
      </Container>
    </WrapperStyle>
  )
}

export default OurFeatures;