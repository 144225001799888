import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Connect(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'connectIcon';
  const customWidth = width ?? '40';
  const customHeight = height ?? '40';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight}
        viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="8" fill="white"/>
        <path d="M25.62 18.75C25.19 18.75 24.85 18.4 24.85 17.98C24.85 17.61 24.48 16.84 23.86 16.17C23.25 15.52 22.58 15.14 22.02 15.14C21.59 15.14 21.25 14.79 21.25 14.37C21.25 13.95 21.6 13.6 22.02 13.6C23.02 13.6 24.07 14.14 24.99 15.11C25.85 16.02 26.4 17.15 26.4 17.97C26.4 18.4 26.05 18.75 25.62 18.75Z" fill="#C50161"/>
        <path d="M29.23 18.75C28.8 18.75 28.46 18.4 28.46 17.98C28.46 14.43 25.57 11.55 22.03 11.55C21.6 11.55 21.26 11.2 21.26 10.78C21.26 10.36 21.6 10 22.02 10C26.42 10 30 13.58 30 17.98C30 18.4 29.65 18.75 29.23 18.75Z" fill="#C50161"/>
        <path opacity="0.4" d="M19.79 22.21L16.52 25.48C16.16 25.16 15.81 24.83 15.47 24.49C14.44 23.45 13.51 22.36 12.68 21.22C11.86 20.08 11.2 18.94 10.72 17.81C10.24 16.67 10 15.58 10 14.54C10 13.86 10.12 13.21 10.36 12.61C10.6 12 10.98 11.44 11.51 10.94C12.15 10.31 12.85 10 13.59 10C13.87 10 14.15 10.06 14.4 10.18C14.66 10.3 14.89 10.48 15.07 10.74L17.39 14.01C17.57 14.26 17.7 14.49 17.79 14.71C17.88 14.92 17.93 15.13 17.93 15.32C17.93 15.56 17.86 15.8 17.72 16.03C17.59 16.26 17.4 16.5 17.16 16.74L16.4 17.53C16.29 17.64 16.24 17.77 16.24 17.93C16.24 18.01 16.25 18.08 16.27 18.16C16.3 18.24 16.33 18.3 16.35 18.36C16.53 18.69 16.84 19.12 17.28 19.64C17.73 20.16 18.21 20.69 18.73 21.22C19.09 21.57 19.44 21.91 19.79 22.21Z" fill="#292D32"/>
        <path d="M29.9701 26.33C29.9701 26.61 29.9201 26.9 29.8201 27.18C29.7901 27.26 29.7601 27.34 29.7201 27.42C29.5501 27.78 29.3301 28.12 29.0401 28.44C28.5501 28.98 28.0101 29.37 27.4001 29.62C27.3901 29.62 27.3801 29.63 27.3701 29.63C26.7801 29.87 26.1401 30 25.4501 30C24.4301 30 23.3401 29.76 22.1901 29.27C21.0401 28.78 19.8901 28.12 18.7501 27.29C18.3601 27 17.9701 26.71 17.6001 26.4L20.8701 23.13C21.1501 23.34 21.4001 23.5 21.6101 23.61C21.6601 23.63 21.7201 23.66 21.7901 23.69C21.8701 23.72 21.9501 23.73 22.0401 23.73C22.2101 23.73 22.3401 23.67 22.4501 23.56L23.2101 22.81C23.4601 22.56 23.7001 22.37 23.9301 22.25C24.1601 22.11 24.3901 22.04 24.6401 22.04C24.8301 22.04 25.0301 22.08 25.2501 22.17C25.4701 22.26 25.7001 22.39 25.9501 22.56L29.2601 24.91C29.5201 25.09 29.7001 25.3 29.8101 25.55C29.9101 25.8 29.9701 26.05 29.9701 26.33Z" fill="#C50161"/>
      </svg>
    </span>
  )
}
export default Connect;