import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Instagram(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'instagramIcon';
  const customWidth = width ?? '40';
  const customHeight = height ?? '40';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight} 
        viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M38.2812 11.25C38.2812 5.98938 34.0106 1.71875 28.75 1.71875H11.25C5.98938 1.71875 1.71875 5.98938 1.71875 11.25V28.75C1.71875 34.0106 5.98938 38.2812 11.25 38.2812H28.75C34.0106 38.2812 38.2812 34.0106 38.2812 28.75V11.25ZM36.7188 11.25V28.75C36.7188 33.1481 33.1481 36.7188 28.75 36.7188H11.25C6.85187 36.7188 3.28125 33.1481 3.28125 28.75V11.25C3.28125 6.85187 6.85187 3.28125 11.25 3.28125H28.75C33.1481 3.28125 36.7188 6.85187 36.7188 11.25Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9719 10.9341C14.9727 10.9341 10.9141 14.9927 10.9141 19.9919C10.9141 24.9911 14.9727 29.0497 19.9719 29.0497C24.9711 29.0497 29.0297 24.9911 29.0297 19.9919C29.0297 14.9927 24.9711 10.9341 19.9719 10.9341ZM19.9719 12.496C24.1089 12.496 27.4678 15.8549 27.4678 19.9919C27.4678 24.1289 24.1089 27.4878 19.9719 27.4878C15.8349 27.4878 12.476 24.1289 12.476 19.9919C12.476 15.8549 15.8349 12.496 19.9719 12.496Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.5932 6.71655C29.1311 6.71655 27.9441 7.90356 27.9441 9.36568C27.9441 10.8278 29.1311 12.0148 30.5932 12.0148C32.0553 12.0148 33.2424 10.8278 33.2424 9.36568C33.2424 7.90356 32.0553 6.71655 30.5932 6.71655ZM30.5932 8.27481C31.1953 8.27481 31.6841 8.76365 31.6841 9.36568C31.6841 9.96771 31.1953 10.4566 30.5932 10.4566C29.9912 10.4566 29.5024 9.96771 29.5024 9.36568C29.5024 8.76365 29.9912 8.27481 30.5932 8.27481Z" fill="white"/>
      </svg>
    </span>
  )
}
export default Instagram;