import styled from "styled-components";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  width: 100%;
  .customHeader{
    background-color: rgba(255, 255, 255, 0.2);
    border:1px solid  rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 40px;
    z-index: 999;
    width: 1280px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius:24px;
    padding: 16px 16px 16px 24px;
    svg.logo{
      position: absolute;
      ${xl(`
          position: inherit;
          width: 90px;
          position: relative;
          top:7px;
      `)}
      ${md(`
        left: -10px;
        top: 5px;
        position: relative;
      `)}
    }
    .languageSelect{
      ${md(`
        margin-right:15px;
      `)}
      &.ant-select-single{
        width: 90px;
        .ant-select-selector{
          border-radius: 24px;
          .ant-select-selection-item{
            text-transform: uppercase;
          }
        }
      }
    }
    .call{
      color: #ffffff;
      font-size: 18px;
      line-height:32.4px;
      font-weight: 500;
      a{
        color: #ffffff;
        &:hover{
          color: #ffffff;
        }
        .callIcon{
          margin-right: 12px;
        }
      }
      ${md(`
        display:none
      `)}
    }
    ${xl(`
      width: 90%;
      height: 38px;
      display: flex;
      position: absolute;
      padding: 10px;
    `)}
    ${md(`
      width: 90%;
      height: 60px;
      padding: 0;
      border-radius:35px;
    `)}
  }
`;