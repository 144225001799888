import styled from "styled-components";
import vapesBg from '../../Assets/Images/vapsaBg.jpg'
import vapsa from '../../Assets/Images/vapsa.png'
import info from '../../Assets/Images/vapsaInfo.png'
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  background-image: url(${vapesBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-bottom: 30px;
  .iconInfo{
    ${lg(`
      display: initial;
    `)}
  }
`;

export const GetInStyle = styled.div`
  background-image: url(${vapsa});
  background-repeat: no-repeat;
  background-position: center bottom;
  height: 500px;
  ${xl(`
      height: auto;
      padding: 30px 15px;
      background-size: contain;
    `)}
  .info{
    background-image: url(${info});
    background-repeat: no-repeat;
    width: 100%;
    height: 348px;
    background-position: center bottom;
    position: relative;
    border-radius:20px;
    top: 70px;
    ${lg(`
      border-radius:25px;
      height:auto;
      padding: 0;
      top: 0;
    `)}
    ${md(`
      border-radius:25px;
    `)}
    .container{
      width: 920px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      ${lg(`
        width:100%;
      `)}
      h2{
        font-size: 34px;
        line-height: 41.15px;
        font-weight: 500;
        margin: 0 0 32px 0;
        color: #ffffff;
        ${lg(`
          padding: 22px;
          font-size: 20px;
          line-height: 30.45px;
          margin: 0;
        `)};
      }
      .iconInfo {
        ${lg(`
          padding: 0 15px;
        `)};
      }
      .footerInfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${lg(`
          justify-content: initial;
        `)}
        ${md(`
          margin-bottom:8px;
        `)}
        .footerIcon{
          margin-right: 20px;
        }
        .footertext{
          p{
            font-size: 16px;
            line-height: 28.8px;
            font-weight: 500;
            margin: 0;
            padding: 0;
            color: #fff;
            ${md(`
               font-size: 14px;
                line-height: 25px;
            `)}
          }
          h5{
            font-size: 18px;
            line-height: 32.4px;
            font-weight: 500;
            margin: 0;
            padding: 0;
            color: #fff;
            ${md(`
               font-size: 15px;
                line-height: 30px;
            `)}
          }
        }
        a{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .inTouch{
        width: 100%;
        justify-content: center;
        ${lg(`
          display: initial;
        `)}
        .footerSocial{
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          ${lg(`
            margin:0;
            padding:0;
          `)}
          li{
            margin: 30px 20px 0 20px;
            ${lg(`
              margin:15px 10px;
            `)}
            ${md(`
              margin:8px 10px;
            `)}
            a{
              .anticon{
                ${lg(`
                  width: 30px
                `)}
              }
            }
          }
        }
      }
    }
  }
`;