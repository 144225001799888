import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let currentYear = new Date().getFullYear();
const setCurrentYear = () => {
  currentYear = new Date().getFullYear();
};
setCurrentYear();

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          bannerSubTitle: 'Vending Machines',
          bannerText: 'Wholesale and retail sale of electronic cigarette, vending machine with touch screens and the best technology for the use of advertising.',
          aboutVapesTitle: 'About Juicy Vapes',
          aboutVapesText: 'Our company was founded in 2023 after long research and arduous hours of work. We started with exporting vending machines and vapes to the Dominican Republic market. Come be part of our exciting new stage of economic growth in the area. We provide vapes and vending machines with the best technology on the market.',
          ourFeaturesTitle: 'Our Features',
          featuresCardOne: 'Capacity for 110 vapes',
          featuresCardTwo: 'Intuitive touch interface',
          featuresCardThree: 'Secure card payment',
          featuresCardFour: 'Advertising with quality',
          featuresCardFive: 'Accessible and user-friendly',
          featuresCardSix: 'Download Juicy Vapes Brochure',
          downloadNow: 'Download Now',
          getInTouch: 'Get in Touch',
          contactPerson: 'Contact Person',
          contactUs: 'Contact Us',
          emailAddress: 'Email Address',
          copyright: `Copyright © ${currentYear} Juicy Vapes, All Rights Reserved.`,
        },
      },
      es: {
        translation: {
          bannerSubTitle: 'máquinas expendedoras',
          bannerText: 'Venta al por mayor y menor de cigarrillos electrónicos, máquinas expendedoras con pantallas táctiles y la mejor tecnología para el uso de publicidad.',
          aboutVapesTitle: 'Acerca de Juicy Vapes',
          aboutVapesText: 'Nuestra empresa fue fundada en 2023 después de una larga investigación y arduas horas de trabajo. Comenzamos exportando máquinas expendedoras y vaporizadores al mercado de República Dominicana. Ven y sé parte de nuestra emocionante nueva etapa de crecimiento económico en la zona. Proporcionamos vaporizadores y máquinas expendedoras con la mejor tecnología del mercado.',
          ourFeaturesTitle: 'Nuestras características',
          featuresCardOne: 'Capacidad para 110 vaporizadores',
          featuresCardTwo: 'Interfaz táctil intuitiva',
          featuresCardThree: 'Pago seguro con tarjeta',
          featuresCardFour: 'Publicidad con calidad',
          featuresCardFive: 'Accesible y fácil de usar',
          featuresCardSix: 'Descargar el folleto de Juicy Vapes',
          downloadNow: 'Descargar ahora',
          getInTouch: 'Ponerse en contacto',
          contactPerson: 'Persona de contacto',
          contactUs: 'Contacta con nosotras',
          emailAddress: 'Dirección de correo electrónico',
          copyright: `Copyright © ${currentYear} Juicy Vapes, Todos los derechos reservados.`,
        },
      },
    },
    lng: 'en', 
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
