import React from 'react'
import { GetInStyle, WrapperStyle } from './GetInTouch.styles';
import { Container } from '../Container/Container';
import { Flex } from 'antd';
import Contact from '../Icons/Contact';
import Connect from '../Icons/Connect';
import Email from '../Icons/Email';
import Facebook from '../Icons/Facebook';
import Instagram from '../Icons/Instagram';
import { useTranslation } from "react-i18next";
const GetInTouch = () => {
  const { t } = useTranslation();
  return(
    <WrapperStyle> 
      <Container>
        <GetInStyle>
          <div className='info'>
            <div className='container'>
              <h2>{t('getInTouch')}</h2>
              <Flex align='center' justify='space-between' className='iconInfo'>
                <div className='footerInfo'>
                  <div className='footerIcon'>
                    <Contact />
                  </div>
                  <div className='footertext'>
                    <p>{t('contactPerson')}</p>
                    <h5>Nahiomy Encarnacion</h5>
                  </div>
                </div>
                <div className='footerInfo'>
                  <a href="tel:+1(809) 327-0885">
                    <div className='footerIcon'>
                      <Connect />
                    </div>
                    <div className='footertext'>
                      <p>{t('contactUs')}</p>
                      <h5>+1 (809) 327-0885</h5>
                    </div>
                  </a>
                </div>
                <div className='footerInfo'>
                  <a href = "mailto: juicyvapes.company@gmail.com">
                    <div className='footerIcon'>
                      <Email />
                    </div>
                    <div className='footertext'>
                      <p>{t('emailAddress')}</p>
                      <h5>juicyvapes.company@gmail.com</h5>
                    </div>
                  </a>
                </div>
              </Flex>
              <Flex align='center' justify='space-between' className='inTouch'>
                <ul className='footerSocial'>
                  <li>
                    <a href='https://www.facebook.com/' target='_blank' rel="noreferrer"><Facebook /></a>
                  </li>
                  <li>
                    <a href='https://www.instagram.com/juicyvapes_company/?igsh=djNqc2E0cmNpNTJy&utm_source=qr' target='_blank' rel="noreferrer"><Instagram /></a>
                  </li>
                </ul>
              </Flex>
            </div>
          </div>
        </GetInStyle>
      </Container>
    </WrapperStyle>
  )
}

export default GetInTouch;