import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Facebook(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'facebookIcon';
  const customWidth = width ?? '40';
  const customHeight = height ?? '40';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight} 
        viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1817 15.4545V17.2727H16.3635V20H18.1817V28.1818H21.8181V20H24.2363L24.5453 17.2727H21.8181V15.6818C21.8181 14.9455 21.8908 14.5545 23.0271 14.5545H24.5453V11.8181H22.109C19.1999 11.8182 18.1817 13.1818 18.1817 15.4545Z" fill="white"/>
        <path d="M20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0ZM20 38.1818C9.95844 38.1818 1.8182 30.0416 1.8182 20C1.8182 9.95844 9.95844 1.8182 20 1.8182C30.0416 1.8182 38.1818 9.95844 38.1818 20C38.1818 30.0416 30.0416 38.1818 20 38.1818Z" fill="white"/>
      </svg>
    </span>
  )
}
export default Facebook;