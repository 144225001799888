import styled from "styled-components";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  background: #000;
  padding: 20px;
  p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 500;
    color: #ffffff;
    margin: 0;
    ${lg(`
      font-size: 10px;
    `)}
  }
`;