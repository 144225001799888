import React from "react";
import { BannerFooterText, BannerText, WrapperStyle } from "./Banner.styles";
import { Container } from "../Container/Container";
import Heading from "../Heading";
import { useTranslation } from "react-i18next";
import useMediaQuery from '../../Hook/useMediaQuery';
const Banner = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const layoutClass = isMobile ? "responsiveLayout wrapperEven" : "webLayout wrapperEven";
  const { t } = useTranslation();
  return(
    <WrapperStyle className={layoutClass}>
      <Container>
        <BannerText>
          <Heading heading={'Juicy Vapes'} subheading={t('bannerSubTitle')} text={t('bannerText')}/>
        </BannerText>
      </Container>
      <BannerFooterText>
        <Heading heading={t('aboutVapesTitle')} text={t('aboutVapesText')}/>
      </BannerFooterText>
   </WrapperStyle>
  )
}

export default Banner;
