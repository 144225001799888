import styled from "styled-components";
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const ContainerStyle = styled.div`
    max-width: 1280px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    ${xl(`
      max-width:100%;
      padding:0;
    `)}
`