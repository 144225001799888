import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Contact(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'contactIcon';
  const customWidth = width ?? '41';
  const customHeight = height ?? '40';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight} 
        viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="40" height="40" rx="8" fill="white"/>
        <path opacity="0.4" d="M20.5 10C17.88 10 15.75 12.13 15.75 14.75C15.75 17.32 17.76 19.4 20.38 19.49C20.46 19.48 20.54 19.48 20.6 19.49C20.62 19.49 20.63 19.49 20.65 19.49C20.66 19.49 20.66 19.49 20.67 19.49C23.23 19.4 25.24 17.32 25.25 14.75C25.25 12.13 23.12 10 20.5 10Z" fill="#292D32"/>
        <path d="M25.58 22.15C22.79 20.29 18.24 20.29 15.43 22.15C14.16 23 13.46 24.15 13.46 25.38C13.46 26.61 14.16 27.75 15.42 28.59C16.82 29.53 18.66 30 20.5 30C22.34 30 24.18 29.53 25.58 28.59C26.84 27.74 27.54 26.6 27.54 25.36C27.53 24.13 26.84 22.99 25.58 22.15Z" fill="#C50161"/>
      </svg>
    </span>
  )
}
export default Contact;