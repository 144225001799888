import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function HeaderCall(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'callIcon';
  const customWidth = width ?? '20';
  const customHeight = height ?? '21';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight}
        viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.62 9.24995C15.19 9.24995 14.85 8.89994 14.85 8.47994C14.85 8.10994 14.48 7.33995 13.86 6.66995C13.25 6.01995 12.58 5.63995 12.02 5.63995C11.59 5.63995 11.25 5.28995 11.25 4.86995C11.25 4.44995 11.6 4.09995 12.02 4.09995C13.02 4.09995 14.07 4.63995 14.99 5.60995C15.85 6.51995 16.4 7.64995 16.4 8.46995C16.4 8.89995 16.05 9.24995 15.62 9.24995Z" fill="white"/>
        <path d="M19.23 9.24997C18.8 9.24997 18.46 8.89997 18.46 8.47997C18.46 4.92997 15.57 2.04997 12.03 2.04997C11.6 2.04997 11.26 1.69997 11.26 1.27997C11.26 0.859969 11.6 0.499969 12.02 0.499969C16.42 0.499969 20 4.07997 20 8.47997C20 8.89997 19.65 9.24997 19.23 9.24997Z" fill="white"/>
        <path opacity="0.4" d="M9.79 12.71L6.52 15.98C6.16 15.66 5.81 15.33 5.47 14.99C4.44 13.95 3.51 12.86 2.68 11.72C1.86 10.58 1.2 9.43997 0.72 8.30997C0.24 7.16997 0 6.07997 0 5.03997C0 4.35997 0.12 3.70997 0.36 3.10997C0.6 2.49997 0.98 1.93997 1.51 1.43997C2.15 0.80997 2.85 0.499969 3.59 0.499969C3.87 0.499969 4.15 0.55997 4.4 0.67997C4.66 0.79997 4.89 0.97997 5.07 1.23997L7.39 4.50997C7.57 4.75997 7.7 4.98997 7.79 5.20997C7.88 5.41997 7.93 5.62997 7.93 5.81997C7.93 6.05997 7.86 6.29997 7.72 6.52997C7.59 6.75997 7.4 6.99997 7.16 7.23997L6.4 8.02997C6.29 8.13997 6.24 8.26997 6.24 8.42997C6.24 8.50997 6.25 8.57997 6.27 8.65997C6.3 8.73997 6.33 8.79997 6.35 8.85997C6.53 9.18997 6.84 9.61997 7.28 10.14C7.73 10.66 8.21 11.19 8.73 11.72C9.09 12.07 9.44 12.41 9.79 12.71Z" fill="white"/>
        <path d="M19.9701 16.83C19.9701 17.11 19.9201 17.4 19.8201 17.68C19.7901 17.76 19.7601 17.84 19.7201 17.92C19.5501 18.28 19.3301 18.62 19.0401 18.94C18.5501 19.48 18.0101 19.87 17.4001 20.12C17.3901 20.12 17.3801 20.13 17.3701 20.13C16.7801 20.37 16.1401 20.5 15.4501 20.5C14.4301 20.5 13.3401 20.26 12.1901 19.77C11.0401 19.28 9.8901 18.62 8.7501 17.79C8.3601 17.5 7.9701 17.21 7.6001 16.9L10.8701 13.63C11.1501 13.84 11.4001 14 11.6101 14.11C11.6601 14.13 11.7201 14.16 11.7901 14.19C11.8701 14.22 11.9501 14.23 12.0401 14.23C12.2101 14.23 12.3401 14.17 12.4501 14.06L13.2101 13.31C13.4601 13.06 13.7001 12.87 13.9301 12.75C14.1601 12.61 14.3901 12.54 14.6401 12.54C14.8301 12.54 15.0301 12.58 15.2501 12.67C15.4701 12.76 15.7001 12.89 15.9501 13.06L19.2601 15.41C19.5201 15.59 19.7001 15.8 19.8101 16.05C19.9101 16.3 19.9701 16.55 19.9701 16.83Z" fill="white"/>
      </svg>
    </span>
  )
}
export default HeaderCall;