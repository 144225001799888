import styled from "styled-components";
import bannerImage from '../../Assets/Images/banner.jpg'
import bannnerLogo from '../../Assets/Images/Juicy.png'
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background-image: url(${bannerImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  z-index: 0;
  margin-bottom: -1px;
  ${xl(`
     height: 75vh;
  `)}
  ${lg(`
    height: 65vh;
  `)}
   ${md(`
     height: 700px;
     display: inherit;
  `)}
`;

export const BannerText = styled.div`
  background-image: url(${bannnerLogo});
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 275px;
  display: flex;
  align-items: flex-end;
  ${xl(`
     background-size: contain;
     height: auto;
     width: 95%;
     margin:0 auto;
  `)}
  .heading {
    max-width: 568px;
    ${xl(`
      max-width:100%;
      padding: 0 15px;
    `)}
    ${md(`
        padding-top: 12rem;
    `)}
    .title{
      color: #ffffff;
      font-size:86px;
      line-height: 104.08px;
      font-weight: 700;
      text-shadow:
      -1px -1px 0 rgba(0,0,0,0.5),  
      1px -1px 0 rgba(0,0,0,0.5),
      -1px 1px 0 rgba(0,0,0,0.5),
      1px 1px 0 rgba(0,0,0,0.5); 
      ${lg(`
        font-size:40px;
        line-height: 45px;
      `)}
    }
    .subTitle{
      color: #ffffff;
      font-size:43px;
      line-height: 52.04px;
      font-weight: 500;
      text-shadow:
      -1px -1px 0 rgba(0,0,0,0.2),  
      1px -1px 0 rgba(0,0,0,0.2),
      -1px 1px 0 rgba(0,0,0,0.2),
      1px 1px 0 rgba(0,0,0,0.2); 
      ${lg(`
        font-size: 25px;
        line-height: 30px;
      `)}
    }
    p{
      color: #ffffff;
      font-size:16px;
      line-height: 28.8px;
      font-weight: 400;
      margin: 32px 0 0 0;
      ${lg(`
        font-size: 14px;
        line-height: 24.8px;
        margin:10px 0 0 0;
      `)}
    }
  }
`;

export const BannerFooterText = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgb(2,9,41);
  background: linear-gradient(180deg, rgba(2,9,41,0.03974089635854339) 0%, rgba(2,9,41,1) 100%);
  .heading {
      max-width: 1280px;
      padding-bottom: 35px;
      margin: 0 auto;
      ${xl(`
        max-width:100%;
        padding: 0 15px 25px 15px;
        border-bottom: 1px solid #020929;
      `)}
      .title{
        color: #ffffff;
        font-size:24px;
        line-height: 29.05px;
        font-weight: 500;
      }
      .subTitle{
        color: #ffffff;
        font-size:18px;
        line-height: 32.4px;
        font-weight: 300;
      }
      p{
        color: #ffffff;
        font-size:16px;
        line-height: 28.8px;
        font-weight: 400;
        margin: 24px 0 0 0;
        text-align: justify;
        ${lg(`
          font-size: 14px;
          line-height: 24.8px;
          margin:10px 0 0 0;
      `)}
      }
    }
`;
