import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Container/Container';
import { WrapperStyle } from './Header.styles';
import { Select } from 'antd';
import { Flex } from 'antd';
import { Logo } from '../Icons';
import { HeaderCall } from '../Icons';

interface LanguageOption {
  value: string;
  label: string;
}

const languageOptions: LanguageOption[] = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  // Add more languages as needed
];

const { Option } = Select;

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (value: string) => {
    i18n.changeLanguage(value);
  };
  return(
    <WrapperStyle>
      <Container>
      {/*{t('greeting')}*/}
      <Flex align='center' justify='space-between' className='customHeader'>
        <div className='call'><a href="tel:829-770-1829"><HeaderCall/>+1 (809) 327-0885</a></div>
        <div className='headerLogo'><Logo /></div>
        <div className='languageDropdown'>
        <Select
            id="language-select"  
            onChange={changeLanguage}
            style={{width:'120px'}}
            value={i18n.language}
            className='languageSelect'
          >
          {languageOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        </div>
      </Flex>
      </Container>
    </WrapperStyle>
  )
}

export default Header;