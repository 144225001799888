import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Capecity(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'capecityIcon';
  const customWidth = width ?? '22';
  const customHeight = height ?? '50';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight}
        viewBox="0 0 22 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.7344 14.4141V6.66016C17.7344 6.12305 17.2949 5.68359 16.7578 5.68359H15.4004V0.976562C15.4004 0.439453 14.9609 0 14.4238 0H9.67774C9.14063 0 8.70117 0.439453 8.70117 0.976562V5.68359H7.34375C6.80664 5.68359 6.36719 6.12305 6.36719 6.66016V14.2969H4.35547C1.95313 14.2969 0 16.4062 0 18.9941V45.3125C0 47.9004 1.95313 50.0098 4.35547 50.0098H16.7578C19.1602 50.0098 21.1133 47.9004 21.1133 45.3125V18.9844C21.1133 16.7578 19.668 14.8926 17.7344 14.4141ZM10.6543 1.95312H13.4473V5.68359H10.6543V1.95312ZM8.32031 7.63672H15.7715V14.2969H8.32031V7.63672ZM19.1602 45.3027C19.1602 46.8164 18.0762 48.0469 16.7578 48.0469H4.35547C3.02734 48.0469 1.95312 46.8164 1.95312 45.3027V18.9844C1.95312 17.4707 3.03711 16.2402 4.35547 16.2402H16.7578C18.0859 16.2402 19.1602 17.4707 19.1602 18.9844V45.3027ZM9.11133 21.4941H6.73828C5.36133 21.4941 4.23828 22.6172 4.23828 23.9941V32.959C4.23828 34.3359 5.36133 35.459 6.73828 35.459H9.11133C10.4883 35.459 11.6113 34.3359 11.6113 32.959V23.9941C11.6113 22.6172 10.4883 21.4941 9.11133 21.4941ZM9.85352 32.959C9.85352 33.3691 9.52148 33.7012 9.11133 33.7012H6.73828C6.32812 33.7012 5.99609 33.3691 5.99609 32.959V23.9941C5.99609 23.584 6.32812 23.252 6.73828 23.252H9.11133C9.52148 23.252 9.85352 23.584 9.85352 23.9941V32.959Z" fill="white"/>
      </svg>
    </span>
  )
}
export default Capecity;