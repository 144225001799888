import React from 'react'
import { WrapperStyle } from './Copyright.styles'
import { Container } from '../Container/Container'
import { useTranslation } from "react-i18next";
const Copyright = () => {
  const { t } = useTranslation();
  return(
    <WrapperStyle>
      <Container>
        <p>{t('copyright')}</p>
      </Container>
    </WrapperStyle>
  )
}

export default Copyright;