import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Email(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'emailIcon';
  const customWidth = width ?? '41';
  const customHeight = height ?? '40';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight} 
        viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="40" height="40" rx="8" fill="white"/>
        <path d="M14.5 21C11.74 21 9.5 23.23 9.5 26C9.5 28.77 11.74 31 14.5 31C17.26 31 19.5 28.76 19.5 26C19.5 23.24 17.27 21 14.5 21ZM12.46 23.96H14.5C14.88 23.96 15.18 24.27 15.18 24.64C15.18 25.01 14.87 25.32 14.5 25.32H12.46C12.08 25.32 11.78 25.01 11.78 24.64C11.78 24.27 12.08 23.96 12.46 23.96ZM16.54 28.04H12.45C12.07 28.04 11.77 27.73 11.77 27.36C11.77 26.99 12.08 26.68 12.45 26.68H16.54C16.92 26.68 17.22 26.99 17.22 27.36C17.22 27.73 16.92 28.04 16.54 28.04Z" fill="#C50161"/>
        <path opacity="0.4" d="M25.5 11H15.5C12.5 11 10.5 12.5 10.5 16V19.14C10.5 19.87 11.25 20.33 11.92 20.04C13.02 19.56 14.27 19.38 15.58 19.59C18.2 20.02 20.34 22.09 20.87 24.69C21.02 25.45 21.04 26.19 20.94 26.9C20.86 27.49 21.34 28.01 21.93 28.01H25.5C28.5 28.01 30.5 26.51 30.5 23.01V16.01C30.5 12.5 28.5 11 25.5 11Z" fill="#292D32"/>
        <path d="M20.5 19.87C19.66 19.87 18.81 19.61 18.16 19.08L15.03 16.58C14.71 16.32 14.65 15.85 14.91 15.53C15.17 15.21 15.64 15.15 15.96 15.41L19.09 17.91C19.85 18.52 21.14 18.52 21.9 17.91L25.03 15.41C25.35 15.15 25.83 15.2 26.08 15.53C26.34 15.85 26.29 16.33 25.96 16.58L22.83 19.08C22.19 19.61 21.34 19.87 20.5 19.87Z" fill="#C50161"/>
      </svg>
    </span>
  )
}
export default Email;