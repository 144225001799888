import styled from "styled-components";
import vending from '../../Assets/Images/featuresHero.png';
import { md, lg, xl } from "../../ThemeConfig/Variables";

export const WrapperStyle = styled.div`
  display: flex;
  background: #020929;
  padding: 0 0 80px 0;
  ${xl(`
    padding: 0 15px;
    margin-bottom: -1px;
  `)}
`;

export const FeaturesStyle = styled.div`
  h2{
    font-size: 34px;
    line-height: 41.15px;
    font-weight: 500;
    color: #ffffff;
    margin: 80px 0 50px 0;
    ${xl(`
      margin: 50px 0;
    `)}
    ${md(`
      margin: 45px 0;
    `)}
  }
  .featuresList{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 240px;
    border: 1px solid #FF1F8D;
    border-radius: 20px;
    padding: 44px 40px;
    background: rgb(32,19,91);
    background: linear-gradient(215deg, rgba(32,19,91,1) 0%, rgba(190,4,95,1) 100%);
    ${md(`
      padding: 20px;
      height:180px;
    `)}
    h4{
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      width: 180px;
      ${md(`
        font-size: 14px;
        line-height: 25px;
        width:125px;
      `)}
    }
    &:last-child{
      padding:44px 40px 0 40px;
      ${md(`
          padding: 20px 20px 0 20px;
      `)}
    }
    .downloadCard{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      img{
        height: auto;
        position: relative;
        bottom: -12px;
        ${md(`
          width:100%;
        `)}
      }
      .downloadBtn{
        position: relative;
        top: -40px;
        width: 120px;
        height: 34px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.8);
        font-size: 12px;
        line-height: 18px;
        width: 500;
        color: #fff;
        span{
          position:relative;
          top:3px;
        }
      }
    }
  }
  .vendingMachin{
    background-image: url(${vending});
    height: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 34rem;
    ${xl(`
      padding: 0 20rem 0 0;
      margin-bottom: 2rem;
      background-size: contain;
      background-position: bottom right;
      background-size:40%;
    `)}
    ${lg(`
      padding: 0 0 42rem 0;
      margin-bottom: 2rem;
      background-size: contain;
      background-position: bottom center;
    `)}
    ${md(`
      padding: 0 0 0 0;
      margin-bottom: 2rem;
      background-size: contain;
      background-position: bottom center;
      background: unset;
    `)}
  }
`;
