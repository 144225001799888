import React from "react";
interface IconProps{
  className?: string;
  width?: string;
  height?: string;
  strockeWidth?: string;
  fill?: string;
}
function Advertising(props: IconProps){
  const { className, width, height, strockeWidth, fill } = props;
  const customClass = className ?? 'advertisingIcon';
  const customWidth = width ?? '45';
  const customHeight = height ?? '30';
  const customStrockWidth = strockeWidth ?? '1.5';
  const customFill = fill ?? '';

  return(
    <span className="anticon">
      <svg 
        className={customClass}
        width={customWidth} 
        height={customHeight}
        viewBox="0 0 45 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.75 0H5.41667C2.42917 0 0 2.43125 0 5.41667V24.1667C0 27.1542 2.43125 29.5833 5.41667 29.5833H38.75C41.7375 29.5833 44.1667 27.1521 44.1667 24.1667V5.41667C44.1667 2.42917 41.7375 0 38.75 0ZM41.6667 24.1667C41.6667 25.775 40.3563 27.0833 38.75 27.0833H5.41667C3.80833 27.0833 2.5 25.775 2.5 24.1667V10.4875H41.6667V24.1667ZM41.6667 7.98542H2.5V5.41667C2.5 3.80833 3.80833 2.5 5.41667 2.5H38.75C40.3563 2.5 41.6667 3.80833 41.6667 5.41667V7.98542ZM29.1667 21.9542C29.1667 21.2625 29.7271 20.7042 30.4167 20.7042H34.5833C35.2729 20.7042 35.8333 21.2646 35.8333 21.9542C35.8333 22.6438 35.2729 23.2042 34.5833 23.2042H30.4167C29.7271 23.2042 29.1667 22.6438 29.1667 21.9542Z" fill="white"/>
      </svg>
    </span>
  )
}
export default Advertising;